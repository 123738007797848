//
// document.scss
//
.content p {
  margin: 0;
  line-height: normal;
  padding: 0;
}

.content ul {
  padding: 0px 0px 0px 20px;
  margin: 0;
}

.w-45p {
  width: 45%;
  padding: 0;
}

.m-10p {
  margin-left: 10%;
}

.content a {
  width: fit-content;
  padding: 0;
}

.document-section {
  padding-top: 55px;
}