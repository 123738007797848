// 
// banner.scss
// 
@mixin bg-center {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.banner {
    padding-top: 60px;
}
