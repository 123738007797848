// 
// join.scss
// 

.img-background {
    position: absolute;
    z-index: -1;
}

.features {
    gap: 1rem;
    justify-items: center;
    align-items: center;
}

.features-item {
    justify-items: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    margin-bottom: 0px;

    img {
        width: 50%;
    }

    .h5 {
        margin-top: 5px;
        font-weight: bold;
        color: white;
        font-size: 16px;
    }

    @media (max-width: 450px) {
        .h5 {
            font-size: 12px;
        }
    }
}

.text-title {
    color: white;
    font-weight: bold;
    font-size: 18px;

    p {
        font-size: 18px;
        text-align: left;
    }
    @media (max-width: 770px) {
        font-size: 15px;
        p {
            font-size: 15px;
        }

    }
    @media (max-width: 450px) {
        font-size: 12px;
        p {
            font-size: 12px;
        }

    }
}

.text-strong {
    color: #00E9C4;
}

.text-description {
    color: #079CFC !important;
    font-size: 18px;
}