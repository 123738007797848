// 
// contact.scss
// 
.contact-us {
    p {
        color: $info;
        font-size: 1rem;
    }

    h4 {
        color: white;
        font-size: 1.4rem;
    }

    @media (max-width: 450px) {
        h4 {
        font-size: 1rem;
        }
    }
}

.contact-logo {
    margin-left: 20px;

}