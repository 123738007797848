// 
// footer.scss
// 
.footer {
    padding: 80px 0px 60px;
    .footer-list-menu {
        li {
            a {
                display: inline-block;
                font-size: 15px;
                color: rgba($white, 0.5);
                margin-bottom: 14px;
                transition: all 0.4s;
                &:hover {
                    color: rgba($white, 0.9);
                }
            }
        }
    }    
    .social-icons-list {
        li {
            margin-bottom: .5rem;
            a {
                display: inline-block;
                width: 32px;
                height: 32px;
                line-height: 32px;
                text-align: center;
                font-size: 15px;
                color: $dark;
                transition: all 0.4s;
                background-color: $light;
                border-radius: 50%;
                &:hover {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}

.footer-alt {
    background-color: darken($footer-bg, 2%);
}