// 
// games.scss
// 

.game-box {
    max-width: 350px;
    margin: 0px auto 30px;

    .card-body {
        padding: 0px;
    }
}

.game-title {
    background-image: url("../img/game_header.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 62px;
}

.game-name {
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 25px 20px;
    text-align: left;
}

.game-image {
    img {
        width: 100%;
        height: auto;
    }
}